
import { Options, Vue } from "vue-class-component";
import { Image } from "@/types/Album";

@Options({
  props: {
    folder: {} as string,
    image: {} as Image,
    index: {} as number,
    isLast: {} as boolean
  }
})
export default class AlbumDetailItem extends Vue {
  //properties
  folder!: string;
  image!: Image;
  index!: number;
  isLast!: boolean;

  //computed properties
  get imageExtension() {
    return (this.image.extension === undefined)
      ? ".jpg" :  this.image.extension;
  }

  get previousImageHref() {
    return "#img-" + (this.index - 1).toString();
  }

  get imageId() {
    return "img-" + this.index.toString();
  }

  get ImageHref() {
    return "#" + this.imageId;
  }
  
  get nextImageHref() {
    return "#img-" + (this.index + 1).toString();
  }

  get closeLabel() {
    return process.env.VUE_APP_LOCALIZATION_CLOSE;
  }

  get previousLabel() {
    return process.env.VUE_APP_LOCALIZATION_PREVIOUS;
  }

  get nextLabel() {
    return process.env.VUE_APP_LOCALIZATION_NEXT;
  }

  imageSourceByWitdh(width: number) {
     return "/assets/images/albums/" + this.folder + "/" + this.image.filename + "-" + width + "w" + this.imageExtension;
  }

}
