
import { Options, Vue } from "vue-class-component";
import { Album } from "@/types/Album";
import AlbumService from "@/services/AlbumService";
import AlbumDetailItem from "@/components/AlbumDetailItem.vue";

@Options({
  components: {
    AlbumDetailItem
  }
})
export default class AlbumDetail extends Vue {
  //properties
  album!: Album;
  loading!: boolean;
  errored!: boolean;

  data() {
    return {
      album: null,
      loading: true,
      errored: false
    };
  }

  mounted() {
    AlbumService.listAlbums()
      .then(response => {       
        this.album = response.find((elt: Album) => elt.folder === this.$route.params.folder);
        if (!this.album) {
          this.$router.push("/404");
        }
        else{
          const titleEl = document.querySelector("head title");
          if (titleEl != null) {
            titleEl.textContent = process.env.VUE_APP_TITLE + " - " + this.album.caption;
          }
        }
      })
      .catch(error => {
        this.errored = true;
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
